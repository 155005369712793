.hero_section {
  width: 100%;
  min-height: 100vh;
  background: url(../images/banner.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @media screen and (max-width: 1024px) {
    min-height: 50vh;
    height: 50vh;
  }
  @media screen and (max-width: 1024px) and (orientation: landscape) {
    min-height: 90vh;
    height: 50vh;
  }
  @media screen and (max-width: 576px) {
    min-height: 90vh;
    height: 90vh;
  }
  &_wrapper {
    max-width: 70%;
    margin: auto;
    text-align: center;
    @media screen and (max-width: 1024px) {
      max-width: 100%;
    }
    h1 {
      color: rgba($color: #fff, $alpha: 0.9);
      @media screen and (max-width: 576px) {
        font-size: 2.3rem;
      }
    }
    .paragraph {
      max-width: 80%;
      margin: auto;
      font-size: 1.3rem;
      color: rgba($color: #fff, $alpha: 0.7);
      text-align: center;
      @media screen and (max-width: 576px) {
        max-width: 100%;
        font-size: 1rem;
      }
    }
  }
  .commercials_circle {
    position: absolute;
    bottom: -60px;
    right: 4rem;
    @media screen and (max-width: 576px) {
      bottom: -40px;
      right: 1.5rem;
    }
    svg {
      width: 140px;
      height: 140px;
      animation: rotate 5s linear infinite;
      path {
        fill: $accent;
      }
      @media screen and (max-width: 576px) {
        width: 80px;
        height: 80px;
      }
    }
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
//about intro
.about_intro {
  display: flex;
  align-items: center;
  gap: 2rem;
  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
  &_left {
    width: 50%;
    @media screen and (max-width: 1023px) {
      width: 100%;
    }
    &_wrapper {
      max-width: 100%;
      height: auto;
      position: relative;
    }
    &_img {
      max-width: 470px;
      padding: 30px;
      margin: auto;
      @media screen and (max-width: 1023px) {
        max-width: 100%;
      }
      @media screen and (max-width: 576px) {
        padding: 20px;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 200px;
        z-index: 10;
      }
      &--float {
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        max-width: 200px;
        height: auto;
        z-index: 2;
        @media screen and (max-width: 576px) {
          max-width: 150px;
        }
      }
    }
  }

  &_right {
    width: 50%;
    height: 100%;
    @media screen and (max-width: 1023px) {
      width: 100%;
    }
    &_content {
      h2 {
        max-width: 90%;
        @media screen and (max-width: 576px) {
          max-width: 100%;
        }
      }
      .paragraph {
        max-width: 80%;
        @media screen and (max-width: 576px) {
          max-width: 100%;
        }
      }
    }
  }
}

//services
.service_section {
  background-color: rgba($color: $accent, $alpha: 0.4);
}
.service_items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.service_item {
  width: 100%;
  min-height: 500px;
  padding: 25px;
  background: rgba($color: white, $alpha: 0.6);
  border-radius: 50px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  transition: all 0.5s ease-in-out;
  cursor: default;
  &:hover {
    background: rgba($color: $accent, $alpha: 0.7);
  }
  @media screen and (max-width: 1024px) {
    min-height: 420px;
  }
  &_wrapper {
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.4rem;
  }
  &_icon {
    width: 60px;
    height: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    svg {
      width: 50px;
      height: 50px;
    }
    &_title {
    }
  }
}

//contact

.contact_section {
  width: 100%;
  height: 100%;
  background: url(../images/contact_parallax.jpg) no-repeat center center;
  background-size: cover;
}
.contact {
  display: flex;
  align-items: center;
  gap: 2rem;
  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
  &_left {
    width: 40%;
    @media screen and (max-width: 1023px) {
      width: 100%;
    }
    &_wrapper {
      max-width: 100%;
      height: auto;
      position: relative;
    }
  }
  &_form_wrapper {
    width: 60%;
    @media screen and (max-width: 1023px) {
      width: 100%;
    }
    .contact_form {
      max-width: 85%;
      margin: auto;
      @media screen and (max-width: 1023px) {
        margin: initial;
      }
      @media screen and (max-width: 1023px) {
        max-width: 95%;
      }
      .form-control {
        width: 100%;
        border-width: 1px;
        border-style: solid;
        border-color: rgba($color: $primary, $alpha: 1);
        border-radius: 5px;
        min-height: 60px;
        transition: border 0.5s ease-in-out;
        background: transparent;
        &:focus {
          outline: none;
          box-shadow: none;
          border-color: rgba($color: $accent, $alpha: 1);
          background: transparent;
        }
        &.is-invalid {
          &:focus {
            outline: none;
            box-shadow: none;
          }
        }
      }
    }
    .snack_bar {
      display: none;
      opacity: 0;
      transition: all 0.4s ease-in-out;
      &.show {
        opacity: 1;
        display: block;
      }
    }
  }
}
