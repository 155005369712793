.navigation_bar {
  width: 100%;
  /* display: flex; */
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100px;
  z-index: 99;
  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    .navbar_logo {
      a {
        font-size: 1.7rem;
        color: rgba($color: #fff, $alpha: 1);
        text-transform: uppercase;
        letter-spacing: 5px;
        font-weight: 300;
        transition: letter-spacing 0.3s ease-in-out;
        font-family: "Inter", sans-serif !important;
        cursor: default;
        &:hover {
          letter-spacing: 6px;
        }
        @media screen and (max-width: 576px) {
          font-size: 1.25rem;
        }
      }
    }
    .menu_bar {
      width: 35px;
      height: 35px;
      display: none;
      visibility: hidden;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 767px) {
        display: flex;
        visibility: visible;
      }
      svg {
        width: 30px;
        height: 30px;
        fill: rgba($color: #fff, $alpha: 0.9);
      }
      // &_line {
      //   width: 100%;
      //   height: 1px;
      //   margin-bottom: 0.5rem;
      //   background-color: #fff;
      //   transition: all 0.4s eass-in-out;
      // }
      // &_line--2 {
      //   width: 100%;
      //   height: 1px;
      //   margin-bottom: 0.5rem;
      //   background-color: #fff;
      // }
      // &_line--3 {
      //   width: 100%;
      //   height: 1px;
      //   background-color: #fff;
      //   transition: all 0.4s eass-in-out;
      // }
      &.clicked {
        .menu_bar_close {
          display: block;
        }
      }
    }
    .menu_bar_close {
      width: 55px;
      height: 55px;
      display: none;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      z-index: 10003;
      position: absolute;
      right: 20px;
      top: 25px;
      svg {
        width: 30px;
        height: 30px;
        stroke: #fff;
      }
    }
  }

  .navbar_menu {
    display: inline-flex;
    align-items: center;
    gap: 2.4rem;
    @media screen and (max-width: 767px) {
      position: absolute;
      display: none;
      flex-direction: column;
      top: 0;
      align-items: flex-start;
      left: 0%;
      height: 100vh;
      padding-top: 30vh;
      padding-left: 30px;
    }
    &_item {
      position: relative;
      a {
        color: #fff;
        font-size: 1.1rem;
        font-size: 200;
        text-transform: capitalize;
        position: relative;
        &:before {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 20px;
          height: 20px;
          max-width: 0;
          background-color: rgba($color: #fff, $alpha: 0.3);
          border-radius: 50%;
          transition: max-width 0.5s ease-in-out;
        }
        &:hover {
          &::before {
            max-width: 100%;
          }
        }
      }
    }
  }
}
